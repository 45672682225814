// export const API_BASE_URL = 'http://192.168.43.72:8000/api/'  //10.100.0.4:8000

// export const API_AUTH_BASE_URL = "http://192.168.43.72:8000/api/"  //52.149.196.155

//PROD IP
//export const API_AUTH_BASE_URL = "https://napid.co:8000/api/"

//export const API_BASE_URL = 'https://napid.co:8000/api/'

//ip-jdf-DEV

// export const API_BASE_URL = 'http://192.168.1.9:8000/api/'

// export const API_AUTH_BASE_URL = 'http://192.168.1.9:8000/api/'

//DEV env ip address
//
// export const API_AUTH_BASE_URL = "http://20.219.33.233:8000/api/";
 //export const API_BASE_URL = "http://20.219.33.233:8000/api/";

 //export const API_AUTH_BASE_URL = "http://20.219.33.233:8000/api/";
 //export const API_BASE_URL = "http://20.219.33.233:8000/api/";

export const API_AUTH_BASE_URL = "https://api.napid.co/api/";
export const API_BASE_URL = "https://api.napid.co/api/";
export const API_VAULT_BASE_URL = "https://api.napid.co/api";

//export const API_AUTH_BASE_URL = "http://localhost:8000/api/";
//export const API_BASE_URL = "http://localhost:8000/api/";
//export const API_VAULT_BASE_URL = "http://localhost:8000/api/";
