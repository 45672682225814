import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Tag, Statistic, Button, Tooltip } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SearchOutlined,
  SwapOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import NavProfile from "./NavProfile";
import NavSearch from "./NavSearch";
import SearchInput from "./NavSearch/SearchInput.js";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";
import moment from "moment";
import { useHistory } from "react-router-dom";
import { useIdleTimer } from "react-idle-timer";
import axios from "axios";
import { API_BASE_URL } from "../../constants/ApiConstant";
import { NapidAdmin } from "../../views/napid-admin";

const baseUrl = API_BASE_URL;
 

const { Countdown } = Statistic;

const { Header } = Layout;

const napidAdmin = localStorage.getItem("napID");

const appUser = localStorage.getItem("appU");

const isVerified = localStorage.getItem("isver");

export const HeaderNav = (props) => {
  const name = localStorage.getItem("name");
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    totalcredit
  } = props;

  const jwtKey = localStorage.getItem("jwtKey");
  
  const [searchActive, setSearchActive] = useState(false);

  const [creditBalance, setCreditBalance] = useState(String);

  const [courrencyCode, setCourrencyCode] = useState(String);

  const [newVendor, setNewVendor] = useState(Boolean);

  let history = useHistory();

  const onSearchActive = () => {
    setSearchActive(true);
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const switchNew = () => {
    if(newVendor) {
      setNewVendor(false);
      setTimeout(() => {
        setNewVendor(true);
      }, 200)
    } else {
      setNewVendor(true);
    }
  };


  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  const profileImg = "/img/avatars/napid.png";

  const creditData = totalcredit === undefined ? `${creditBalance} ${courrencyCode}` : `${totalcredit} ${courrencyCode}`;

  let vendorSno = localStorage.getItem("id");

  useEffect(() => {
    let mounted = true;
    if (!isMobile) {
      onSearchClose();
    }
    // let url = `${baseUrl}portal/vendorCredits?vendorSno=${vendorSno}`
    // let encodedUrl = encodeURI(url);
    // console.log(encodedUrl)
    let body = {
      vendorSno: vendorSno
    }
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" , "Authorization" : "Bearer "+jwtKey},
      url: `${baseUrl}portal/vendorCredits`,
      data: body
    })
      .then((response) => {
        // console.log(response.data);
        let credit = response.data;
        credit.map((data) => {
          let credit = data.creditBalance;
          if(mounted) {
            setCreditBalance(credit);
            setCourrencyCode(data.currencyCdDesc);
          }
        });
      })
      return () => mounted = false
  });


  return (
    <Header
      className={`app-header ${navMode}`}
      style={{ backgroundColor: headerNavColor }}
    >
      <div className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}>
        <Logo logoType={navMode} />
        {/* <Avatar size={45} src={profileImg} /> */}
        <div className="nav" style={{ width: `calc(100% - ${getNavWidth()})` }}>
          <div className="nav-left">
            {
              isVerified === "32" ?
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
              {isNavTop && !isMobile ? null : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onToggle();
                  }}
                >
                  {navCollapsed || isMobile ? (
                    <MenuUnfoldOutlined className="nav-icon" />
                  ) : (
                    <MenuFoldOutlined className="nav-icon" />
                  )}
                </li>
              )}
              {/* {isMobile ? (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  onClick={() => {
                    onSearchActive();
                  }}
                >
                  <SearchOutlined />
                </li>
              ) : (
                <li
                  className="ant-menu-item ant-menu-item-only-child"
                  style={{ cursor: "auto" }}
                >
                  <SearchInput mode={mode} isMobile={isMobile} />
                </li>
              )} */}
            </ul> : ""}
          </div>
          <div style={{
            fontSize:"1.2rem",
            color: "red",
          }}>
          &nbsp;&nbsp;{name}
          </div>
          {/*idle timer space*/} {/* YET to ADD */}
          <div className="nav-right">
            {
              isVerified === "32" ?
              <div className=" d-flex align-items-center align-self-stretch justify-content-end">
              <div style={{ marginRight: "28px" }}>
                <div
                  style={{
                    display: "grid",
                    alignItems: "center",
                    gridTemplateColumns: "auto-track-list",
                    gap: "14px",
                  }}
                >
                  <div
                    style={{
                      display: "grid",
                      alignItems: "center",
                      gridAutoFlow: "column",
                      gridAutoColumns: "max-content",
                      gap: "14px",
                    }}
                  >
                    <Tooltip title="To add credit manually contact support@napid.co">
                      <Button
                        style={{ backgroundColor: "#ff0000", color: "white" }}
                        icon={<PlusOutlined />}
                      ></Button>
                    </Tooltip>
                    <div>
                      <h5
                        className="text-muted"
                        style={{ marginBottom: "unset" }}
                      >
                        Available Credit
                      </h5>
                      <h4
                        style={{
                          fontWeight: 500,
                          color: "#54575c",
                          whiteSpace: "nowrap",
                        }}
                      >
                        {creditData}
                      </h4>
                    </div>
                  </div>
                </div>
              </div>
            </div> : ""}
            <h2
              onClick={() => switchNew()}
              style={{ margin: "20px", color: "#ff0000", cursor: "pointer" }}
            >
              {napidAdmin === "Nap Id Admin" ? <SwapOutlined /> : ""}
            </h2>
            {/* {napidAdmin === "Nap Id Admin" ? <p style={{ margin: "20px", marginLeft: '-20px', fontSize: '1vw', color: "#ff0000", cursor: "pointer" }}>Switch Vendor</p> : ""} */}
            <NavProfile />
            {newVendor ? <NapidAdmin appUser={appUser} xmark='xmark' /> : " "}
          </div>
          <NavSearch active={searchActive} close={onSearchClose} />
        </div>
      </div>
    </Header>
  );
};


//-----------------------HERE ADDED LATEST IMPLEMENTED REDUX CHANGE-----------------

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    totalcredit
  } = theme;
  return { navCollapsed, navType, headerNavColor, mobileNav, currentTheme, totalcredit };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
