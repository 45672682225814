import React from "react";
import PropTypes from "prop-types";
import { Modal, Result } from "antd";

const android = [
  'Android',
  'Linux',
  'null',
  'Linux aarch64',
  'Linux armv5tejl',
  'Linux armv6l',
  'Linux armv7l',
  'Linux armv8l',
  'Linux i686',
  'Linux i686 on x86_64',
  'Linux i686 X11',
  'Linux MSM8960_v3.2.1.1_N_R069_Rev:18',
  'Linux ppc64',
  'Linux x86_64',
  'Linux x86_64 X11',
  'Win16',
  'Win32',
  'WinCE',
  'Windows'
]

export const Success = ({ title, icon, extra, status, napid }) => {
  const openPlaystore = () => {
    window.open(
      "https://play.google.com/store/apps/details?id=com.napidpay.android"
    );
  };

  const appstoreLink = () => {
    window.open("https://apps.apple.com/us/app/napid/id1561970018");
  }

  const openMobileLink = () => {
    let deviceOs = '';
    android.map((version) => {
      if(version === window.navigator.platform) {
        deviceOs = 'Android'
      }
    })
    
    if(deviceOs === 'Android') {
      window.open(
        "https://play.google.com/store/apps/details?id=com.napidpay.android"
      );
    } else {
      window.open("https://apps.apple.com/us/app/napid/id1561970018");
    }
  }

  const styleMargin = {
    marginTop: "-25px",
  };

  const margin = {
    marginTop: "0px",
  };

  const linkStyle = {
    width: "50px",
    marginRight: "20px",
    cursor: "pointer"
  }

  return (
    <Modal
      title=""
      visible={true}
      closable={false}
      maskClosable={false}
      centered={true}
      footer={false}
    >
      <div
        style={napid != undefined ? styleMargin : margin}
        className="text-center"
      >
        <Result
          icon={icon}
          title={title}
          extra={extra}
          status={status}
          // subTitle={content}
        />
      </div>
      {napid != undefined ? (
        <div style={{ textAlign: "center" }}>
          <h4>
            *You need{" "}
            <b
              onClick={() => openMobileLink()}
              style={{ cursor: "pointer", color: "#ff0000" }}
            >
              {napid}
            </b>{" "}
            app to login
          </h4>
          <img
            onClick={() => openPlaystore()}
            style={linkStyle}
            src="/img/others/androidicon.png"
            alt="playstore"
          />
          <img
            onClick={() => appstoreLink()}
            style={linkStyle}
            src="/img/others/appleicon.png"
            alt="playstore"
          />
        </div>
      ) : (
        " "
      )}
    </Modal>
  );
};

Success.prototype = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  status: PropTypes.string,
  napid: PropTypes.string,
};

export default Success;
