import React, { Suspense, lazy, useEffect } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
import { BrowserRouter as Router } from "react-router-dom";
import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import Loading from "components/shared-components/Loading";
import WebVerification from "./webVerification";
import GA4React from "ga-4-react";

const ga4react = new GA4React(process.env.REACT_APP_GOOGLE_ANALYTICS_ID);

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const goToAdminpanel = sessionStorage.getItem("go");

const goToReg = sessionStorage.getItem("reg");

const vendor = localStorage.getItem("id");

function App() {
  console.log(
    "%cnapID",
    "background: red; color: white; font-size: x-large",
    "- User id to sleep mode."
  );
  ga4react.initialize().then(
    (ga4) => {
      ga4.pageview(window.location.pathname + window.location.search);
    },
    (err) => {
      console.error(err);
    }
  );
 
  if (
    window.location.pathname != "/" &&
    window.location.pathname != "/terms" &&
    window.location.pathname != "/privacy-policy" &&
    window.location.pathname != "/auth/login" &&
    window.location.pathname != "/auth/register" &&
    (goToAdminpanel === undefined ||
      goToAdminpanel === null ||
      goToAdminpanel === "") &&
    (vendor === undefined || vendor === null || vendor === "")
  ) {
    window.location.href = "/";
  }
  return (
    <div className="App">
      <Provider store={store}>
        {(goToAdminpanel === undefined ||
          goToAdminpanel === null ||
          goToAdminpanel === "") &&
        (goToReg === undefined || goToReg === null || goToReg === "") ? (
          <Router>
            <Suspense fallback={<Loading cover="page" />}>
              <Switch>
                <Route
                  exact
                  path="/"
                  component={lazy(() => import(`./homepage`))}
                />
                <Route
                  path="/terms"
                  component={lazy(() => import(`./terms`))}
                />
                <Route
                  path="/privacy-policy"
                  component={lazy(() => import(`./privacy`))}
                />
                <ThemeSwitcherProvider
                  themeMap={themes}
                  defaultTheme={THEME_CONFIG.currentTheme}
                  insertionPoint="styles-insertion-point"
                >
                <Route
                  path="/auth/login"
                  component={lazy(() => import(`./views/auth-views/authentication/login-2`))}
                />
                <Route
                    path="/auth/register"
                    component={lazy(() => import(`./views/auth-views/authentication/register-2`))}
                  />

                  {/* <Route
                    path="/webverification"
                    component={lazy(() => import(`./webVerification`))}
                  /> */}
                </ThemeSwitcherProvider>
              </Switch>
            </Suspense>
            </Router>
        ) : goToAdminpanel != undefined ||
          goToAdminpanel != null ||
          goToAdminpanel != "" ? (
          <ThemeSwitcherProvider
            themeMap={themes}
            defaultTheme={THEME_CONFIG.currentTheme}
            insertionPoint="styles-insertion-point"
          >
            <Router>
              <Suspense fallback={<Loading cover="page" />}>
                <Switch>
                  <Route path="/webverification" component={WebVerification} />
                  <Route path="/" component={Views} />
                </Switch>
              </Suspense>
            </Router>
          </ThemeSwitcherProvider>
        ) : (
          ""
        )}
      </Provider>
    </div>
  );
}

export default App;
