import React, { useState, useEffect } from "react";
import { Modal, Select, Button } from "antd";
import AppService from "../../services/AppService";
import { API_BASE_URL } from '../../constants/ApiConstant'
import axios from "axios";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
const cryptoRandomString = require('crypto-random-string');


const { Option } = Select;

const baseUrl = API_BASE_URL;


const buttonStyle = {
  textAlign: "center",
  marginTop: "20px",
};

export const NapidAdmin = ({appUser, xmark}) => {

  let history = useHistory();

  const [vendorDetails, setVendorDetails] = useState([]);

  const [vendorSno, setVendorSno] = useState(Number);

  const [isModalVisible, setIsModalVisible] = useState(true);

  // const vendorListCall = () => {
  // let body = {
  //   appUserSno: 101
  // }
  // AppService.vendorList(body).then((response) => {
  //   alert(JSON.stringify(response))
  // })
  // }

  const appUserSno = localStorage.getItem("appU");

  useEffect(() => {
    //api call and consitions
    let body = {
      appUserSno: appUser,
    };
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" , "Authorization" : "Bearer "+localStorage.getItem("jwtKey")},
      url: `${baseUrl}portal/vendorList`,
      data: body,
    })
      .then((response) => {
        // alert(JSON.stringify(response.data));
        let vendor = [...response.data];
        setVendorDetails(vendor);
      })
      .catch((error) => {console.log(error)});
    // alert(JSON.stringify(vendorDetails));
  }, []);

  const vendorDet = [...vendorDetails];

  const onchange = (value) => {
    // console.log(`selected ${value}`);
    setVendorSno(value);
    localStorage.setItem("id", value);
    for(let i=0;i<vendorDetails.length;i++) {
      if(vendorDetails[i].vendorKey === value) {
        localStorage.setItem("name", vendorDetails[i].vendorName);
        localStorage.setItem("isver", vendorDetails[i].websiteStatus);
        localStorage.setItem("isver", '32');
        // alert(JSON.stringify(vendorDetails[i]))

        localStorage.setItem("host", vendorDetails[i].sleepVaultIP);
        localStorage.setItem("port", vendorDetails[i].sleepVaultPort);
        localStorage.setItem("ssl", vendorDetails[i].sleepVaultSSL);

        localStorage.setItem("aliashost", vendorDetails[i].aliasVaultIP);
        localStorage.setItem("aliasport", vendorDetails[i].aliasVaultPort);
        localStorage.setItem("aliasssl", vendorDetails[i].aliasVaultSSL);


        localStorage.setItem("image", vendorDetails[i].logoPath);
        // setIsModalVisible(false);
      }
    }
  };

  const onSelect = () => {
    // const auth_token = "jwvqjhfbjbh jhbwjfwbfjbjwbjbqjbjb"
    const auth_token = cryptoRandomString({length: 100, type: 'ascii-printable'});
    // console.log(vendorSno);
    localStorage.setItem("auth_token", auth_token);
    window.location.reload();
    history.push("/app/default");
    // console.log("After history.push line");
    // localStorage.setItem("entry", vendor);
  };

  const onSearch = value => {
    // console.log(value);
  }


  const onCancel = () =>  {
    setIsModalVisible(false);
  }

  return (
    <>
      <Modal
        title="Select Vendor to View Details"
        visible={isModalVisible}
        closable={xmark != undefined ? true : false}
        onCancel={onCancel}
        maskClosable={false}
        centered={true}
        footer={false}
      >
        <Select
          style={{ color: "#000" }}
          showSearch
          onSearch={onSearch}
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onChange={onchange}
          style={{ width: "100%" }}
        >
          {vendorDet.map((data) => {
            return (
              <Option key={data.vendorKey} value={data.vendorKey}>
                {data.vendorName}
              </Option>
            );
          })}
        </Select>
        <div style={buttonStyle}>
          <Button
            disabled={!vendorSno}
            type="primary"
            onClick={() => onSelect()}
          >
            Continue
          </Button>
        </div>
      </Modal>
    </>
  );
};

NapidAdmin.prototype = {
  appUser:PropTypes.string,
  xmark:PropTypes.string
}

export default NapidAdmin;
