import React, { useState, useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { Button, Card, Radio, Alert } from "antd";
import { API_BASE_URL } from "../constants/ApiConstant";
import axios from "axios";
import { useIdleTimer } from "react-idle-timer";
import { useHistory } from "react-router-dom";
import { GlobalOutlined, InfoCircleOutlined } from "@ant-design/icons";
import Success from "../views/app-views/constants/Success";
import { AUTH_TOKEN } from "../redux/constants/Auth";
import HeaderNav from "components/layout-components/HeaderNav";


const cryptoRandomString = require('crypto-random-string');

const baseUrl = API_BASE_URL;

const jwtKey = localStorage.getItem("jwtKey");

export const WebVerification = () => {

  // state = {
  //   value: 0};




  //   onChange = (e) => {
  //     // console.log("radio checked", e.target.value);
  //     this.setState({
  //       value: e.target.value,
  //     });
  //   };

  const appU = localStorage.getItem("appU");

  const vendorSno = localStorage.getItem("id");

  const isVerified = localStorage.getItem("isver");

  const [websiteKey, setWebsiteKey] = useState(String);

  const [isBadCre, setBadCre] = useState(false);

  const [vendorWeb, setVendorWeb] = useState(String);

  const [fileNameKey, setFileNameKey] = useState(String);

  const [resultMess, setResult] = useState(false);

  const [notVerified, setNotVerified] = useState(false);

  const [showLoading, setShowLoading] = useState(false);

  let history = useHistory();

  //session management starts --the session will be closed automatically after 2.5 mins--

  const timeout = 1800000;
  const [remaining, setRemaining] = useState(timeout);
  const [elapsed, setElapsed] = useState(0);
  const [isIdle, setIsIdle] = useState(false);
  const [deploymentType, setDeploymentType] = useState(String);

  const radioStyle = {
    display: "inline",
    height: "30px",
    lineHeight: "30px",
    fontSize: "20px",
    color: "gray"
  };


  const handleOnActive = () => setIsIdle(false);
  const handleOnIdle = () => {
    setIsIdle(true);
    // sessionStorage.removeItem(AUTH_TOKEN);
    localStorage.clear();
    window.location.reload();
    history.push(`/auth/login`);
    sessionStorage.setItem("go", "@@$%#$#^@&**(*!(*!)!)!!)!)!%$$#GHVGHVHJ");
  };

  const {
    getRemainingTime,
    // getLastActiveTime,
    getElapsedTime,
  } = useIdleTimer({
    timeout,
    onActive: handleOnActive,
    onIdle: handleOnIdle,
  });

  useEffect(() => {
    // session management starts -- --

    setRemaining(getRemainingTime());
    setElapsed(getElapsedTime());

    setInterval(() => {
      setRemaining(getRemainingTime());
      setElapsed(getElapsedTime());
    }, 1000);

    // if (token !== null && allowRedirect) {
    //   history.push(redirect);
    // }
    // if (showMessage) {
    //   setTimeout(() => {
    //     hideAuthMessage();
    //   }, 3000);
    // }
  }, []);

  useEffect(() => {
    let body = {
      vendorSno: vendorSno,
      type: 29,
    };
    axios({
      method: "post",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + jwtKey },
      url: `${baseUrl}portal/getParamKey`,
      data: body,
    })
      .then((response) => {
        // console.log(response.data.status);
        let key = response.data.status;
        setWebsiteKey(key);
        // let plan = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [websiteKey]);

  useEffect(() => {
    let body = {
      vendorSno: vendorSno,
      type: 53,
    };
    axios({
      method: "post",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + jwtKey },
      url: `${baseUrl}portal/getParamKey`,
      data: body,
    })
      .then((response) => {
        // console.log(response.data.status);
        let key = response.data.status;
        setFileNameKey(key);
        // let plan = response.data;
      })
      .catch((error) => {
        console.log(error);
      });
  }, [fileNameKey]);

  const downloadFile = (filename, _content) => {
    var element = document.createElement("a");
    element.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(_content)
    );
    element.setAttribute("download", filename);

    element.style.display = "none";
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  const cliclDownload = () => {
    var content = `${websiteKey}`;
    var filename = `${fileNameKey}.txt`;

    downloadFile(filename, content);
  };

  useEffect(() => {
    let body = {
      appUserSno: appU,
    };
    axios({
      method: "post",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + jwtKey },
      url: `${baseUrl}portal/vendorSettings`,
      data: body,
    })
      .then((response) => {
        if (response != null) {
          // console.log(response.data);
          let data = response.data.website;
          setVendorWeb(data);
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // if(isVerified === "32") {
    //   setResult(true);
    // }
  }, []);

  const pathStr = `
    PATH : /.napid
  `;
  const followingLink = `
      "${vendorWeb}/.napid/${fileNameKey}.txt"
  `;

  const webVerify = () => {
    const vendorSno = localStorage.getItem("id");

    // if (deploymentType === '' || deploymentType == undefined) {
    //   setBadCre(true);
    // }

    setShowLoading(true);
    let body = {
      vendorSno: vendorSno,
      websiteUrl: `${vendorWeb}/.napid/${fileNameKey}.txt`,
      deploymentType: deploymentType
    };

    axios({
      method: "post",
      headers: { "Content-Type": "application/json", "Authorization": "Bearer " + jwtKey },
      url: `${baseUrl}portal/verifyWebsite`,
      data: body,
    })
      .then((response) => {
        setShowLoading(false);
        let status = response.data.status;
        // console.log(status);
        if (status.toUpperCase() === "WEBSITE VERIFIED") {
          //Website verified
          // const token = "qfffufufuywyuwuuuuuuuuuuuuuuuuu";
          const token = cryptoRandomString({ length: 100, type: 'ascii-printable' });
          localStorage.setItem(AUTH_TOKEN, token);
          setResult(true);
          localStorage.setItem("isver", "32");
          history.push('/auth/login');
          window.location.reload();
          // console.log(response);
        } else {
          setNotVerified(true);
          setResult(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const resultOnOK = () => {
    setResult(false);
    if (!notVerified) {
      history.push('/auth/login');
      window.location.reload();
    }
  };

  const alertStyle = {
    backgroundColor: "#ff0000",
    color: "#fff",
    fontweight: "bold",
    // cursor: "pointer",
  };

  const onNavTypeClick = (value) => {
    setDeploymentType(value);
  };
  const alertStyle1 = {
    backgroundColor: "blue",
    color: "#fff",
    fontweight: "bold",
    // cursor: "pointer",
  };

  return (
    <>

      <HeaderNav />
      <br />
      <br />

      <br />
      <br />
      {/* <Card style={{ marginLeft: '20px', marginRight: '20px' }} >
        <Alert
          style={alertStyle1}
          message="SELECT YOUR DEPLOYMENT MODEL"
          description="Select either napID SAAS Product or Client On-Premises Product. Based on that napID will configure your Protection."
          type="warning"
          showIcon
        />
        <br />

        <br />
        <Radio.Group
          onChange={e => onNavTypeClick(e.target.value)}
        >
          <Radio style={radioStyle} value="s">
            <b><span style={{ color: 'red' }}>napID</span> SAAS Product</b>
          </Radio>
          <Radio style={radioStyle} value="o">
            <b>Client On Premises <span style={{ color: 'red' }}>napID</span>  Product</b>
          </Radio>
        </Radio.Group>
      </Card> */}
      {/* <h1>Website Verification Page</h1>
            <Button type="primary" block onClick={() => cliclDownload()}>download</Button> */}
      <Card style={{ marginLeft: '20px', marginRight: '20px' }} >
        <Alert
          style={alertStyle}
          message="DOMAIN VERIFICATION PENDING"
          description="STEPS TO VERIFY YOUR WEBSITE"
          type="warning"
          showIcon
        />
        <br />
        <h4 style={{ fontSize: '17px' }}>Website Verification Steps</h4>
        <br />
        {isVerified === "32" ? (
          <h5 style={{ color: "#ff0000" }}>
            <InfoCircleOutlined />
            {` Website verified `}
          </h5>
        ) : (
          ""
        )}
        <h3 style={{ color: "#72849a" }}>
          To verify your domain using File Upload, kindly follow the steps
          below:
        </h3>
        <div className="mt-4">
          <h4 className="font-weight-bold">STEP 1:</h4>
          <h5>
            Download your Authentication file using link:{" "}
            <a
              style={{
                color: "#17bcff",
                fontWeight: "bold",
                textDecoration: "underline",
              }}
              onClick={() => cliclDownload()}
            >
              Download Authentication File
            </a>
          </h5>
        </div>
        <div className="mt-4">
          <h4 className="font-weight-bold">STEP 2:</h4>
          <h5>Upload the Authentication file to your server under the path</h5>
          <SyntaxHighlighter
            className="hl-code"
            language="java"
            style={atomDark}
          >
            {pathStr}
          </SyntaxHighlighter>
        </div>
        <div className="mt-4">
          <h4 className="font-weight-bold">STEP 3:</h4>
          <h5>Make sure your file is available under the following link</h5>
          <SyntaxHighlighter
            className="hl-code"
            language="java"
            style={atomDark}
          >
            {followingLink}
          </SyntaxHighlighter>
        </div>
        {/* {isVerified === "32" ? <br /> : ""} */}
        <br />
        <Button
          disabled={resultMess || isVerified === "32"}
          type="primary"
          icon={<GlobalOutlined />}
          onClick={() => webVerify()}
          loading={showLoading}
        >
          Verify Website
        </Button>
      </Card>
      {resultMess ? (
        <Success
          title={!notVerified ? "Website verified successfully!" : "Kindly check you placed .txt file in correct path and folder."}
          status={!notVerified ? "success" : "error"}
          extra={
            <Button type="primary" onClick={() => resultOnOK()}>
              OK
            </Button>
          }
        />
      ) : (
        ""
      )}
      {isBadCre ? (
        <Success
          title="Kindly Select Deployment Type"
          status="error"
          extra={
            <Button type="primary" onClick={() => {
              setBadCre(false);
              setShowLoading(false);}}>
              OK!
            </Button>
            
          }
        />
      ) : (
        ""
      )}

    </>
  );
};

export default WebVerification;