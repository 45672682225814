import { SHOW_CREDIT, CREDIT } from "../constants/Credit";

const initState = {
  showcredit: false,
  totalcredit: undefined,
};

const credit = (state = initState, action) => {
  switch (action.type) {
    case SHOW_CREDIT:
      return {
        ...state,
        totalcredit: action.payload,
      };
    default:
      return state;
  }
};

export default credit;
