import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { AUTH_PREFIX_PATH } from 'configs/AppConfig'

//const goToreg = sessionStorage.getItem("reg");
const token = localStorage.getItem("auth_token");


export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="page"/>}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={lazy(() => import(`./authentication/login`))} />
        <Route path={`${AUTH_PREFIX_PATH}/register`} component={lazy(() => import(`./authentication/register-2`))} />
        <Route path={`${AUTH_PREFIX_PATH}/custom-register`} component={lazy(() => import(`./authentication/forgot-password`))} />
        {
          (token === null || token === undefined) 
          //&& (goToreg === null || goToreg === undefined) 
          ?
          <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
          : <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/register`} />
        }
      </Switch>
    </Suspense>
  )
}

export default AppViews;

