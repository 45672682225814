import React from 'react'
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {

	const termsClick = (e) => {
		e.preventDefault();
		sessionStorage.clear();
		window.open("/terms");
		sessionStorage.setItem("go", "@@#$%#^%^@&^@%&@*&@*&@^*@^*&@*@&^*");
	  };
	
	  const privacyClick = (e) => {
		e.preventDefault()
		sessionStorage.clear();
		window.open('/privacy-policy')
		sessionStorage.setItem("go", '@@#$%#^%^@&^@%&@*&@*&@^*@^*&@*@&^*');
	  };

	return (
		<footer className="footer">
			<span>Copyright  &copy;  {`${new Date().getFullYear()}`} <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights reserved.</span>
			<div>
				<a className="text-gray" href="/#" onClick={(e) => termsClick(e)}>Term & Conditions</a>
				<span className="mx-2 text-muted"> | </span>
				<a className="text-gray" href="/#" onClick={(e) => privacyClick(e)}>Privacy & Policy</a>
			</div>
		</footer>
	)
}

