import { 
  UserOutlined,
  LockOutlined,
  UsergroupAddOutlined,
  SettingOutlined,
  PushpinOutlined,
  HomeOutlined,
  ShareAltOutlined,
  CreditCardOutlined,
  DollarOutlined,
  ApiOutlined,
  CodeOutlined,
  SnippetsOutlined,
  ShakeOutlined,
  WalletOutlined,
  InteractionOutlined,
  GlobalOutlined,
  UnorderedListOutlined,
  TeamOutlined,
  FileProtectOutlined,
  SearchOutlined,
  MobileOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const isNapidAdmin = localStorage.getItem("napID");

const defaultNavTree = [{
  key: 'default',
  path: `${APP_PREFIX_PATH}/default`,
  title: 'Home',
  icon: HomeOutlined,
  breadcrumb: false,
  submenu: []
}]

const usersNavTree = [{
  key: 'users',
  path: `${APP_PREFIX_PATH}/users`,
  title: 'Vault Management',
  icon: LockOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'all-users',
      path: `${APP_PREFIX_PATH}/users/allusers`,
      title: "napID Digital Vault",
      icon: LockOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'add-new-users',
    //   path: `${APP_PREFIX_PATH}/users/adduser`,
    //   title: "Add New User Id",
    //   icon: UsergroupAddOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
  ]
}]

const adminNavTree = [{
  key: 'admin',
  path: `${APP_PREFIX_PATH}/admin`,
  title: 'Admin',
  icon: UserOutlined,
  breadcrumb: false,
  // submenu: []
  submenu: [
    
    // {
    //   key: 'Credit Management',
    //   path: `${APP_PREFIX_PATH}/admin/credit`,
    //   title: 'Billing',
    //   icon: CreditCardOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'Referral Details',
    //   path: `${APP_PREFIX_PATH}/admin/referral`,
    //   title: 'Referrals',
    //   icon: ShareAltOutlined,
    //   breadcrumb: true,
    //   submenu: []
    // },
    {
      key: 'Pricing',
      path: `${APP_PREFIX_PATH}/admin/pricing`,
      title: 'My Pricing',
      icon: DollarOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'id-upload',
      path: `${APP_PREFIX_PATH}/admin/idupload`,
      title: 'Upload Digital Ids',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'report',
      path: `${APP_PREFIX_PATH}/admin/report`,
      title: 'Report',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'integrationdocs',
      path: `${APP_PREFIX_PATH}/admin/integrationdocs`,
      title: 'Integration Docs',
      icon: InteractionOutlined,
      breadcrumb: false,
      submenu: []
    },    
    {
      key:  'usage-report',
      path: `${APP_PREFIX_PATH}/admin/usagereport`,
      title:  'Id Usage Report',
      icon:  TeamOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'admin-settings',
      path: `${APP_PREFIX_PATH}/admin/adminsettings`,
      title: 'Settings',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: isNapidAdmin === "Nap Id Admin" ? 'fees-management': "",
    //   path: isNapidAdmin === "Nap Id Admin" ?`${APP_PREFIX_PATH}/admin/feesmanagement`: " ",
    //   title: isNapidAdmin === "Nap Id Admin" ? 'Fees Management' : "fees",
    //   icon: isNapidAdmin === "Nap Id Admin" ? WalletOutlined : "",
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: isNapidAdmin === "Nap Id Admin" ? 'add-transaction': "",
    //   path: isNapidAdmin === "Nap Id Admin" ?`${APP_PREFIX_PATH}/admin/transactiondetails`: " ",
    //   title: isNapidAdmin === "Nap Id Admin" ? 'Add Transaction' : "",
    //   icon: isNapidAdmin === "Nap Id Admin" ? UnorderedListOutlined : "",
    //   breadcrumb: false,
    //   submenu: []
    // },
  ],
}]


const adminNapNavTree = [{
  key: 'admin',
  path: `${APP_PREFIX_PATH}/admin`,
  title: 'Admin',
  icon: UserOutlined,
  breadcrumb: false,
  // submenu: []
  submenu: [
    {
      key: 'all-users',
      path: `${APP_PREFIX_PATH}/admin/users/allusers`,
      title: "napID Alias Vault",
      icon: LockOutlined,
      breadcrumb: false,
      submenu: []
    },   
    {
      key:  'usage-report',
      path: `${APP_PREFIX_PATH}/admin/usagereport`,
      title:  'Id Usage Report',
      icon:  TeamOutlined ,
      breadcrumb: false,
      submenu: []
    },
    
    {
      key: 'vendor-apps',
      path: `${APP_PREFIX_PATH}/admin/vendorapps`,
      title: 'Vendor Apps',
      icon: PushpinOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'Website Verification',
    //   path: `${APP_PREFIX_PATH}/admin/webverification`,
    //   title: 'Website Verification',
    //   icon: GlobalOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'Credit Management',
    //   path: `${APP_PREFIX_PATH}/admin/credit`,
    //   title: 'Billing',
    //   icon: CreditCardOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'Referral Details',
    //   path: `${APP_PREFIX_PATH}/admin/referral`,
    //   title: 'Referrals',
    //   icon: ShareAltOutlined,
    //   breadcrumb: true,
    //   submenu: []
    // },
    {
      key: 'Pricing',
      path: `${APP_PREFIX_PATH}/admin/pricing`,
      title: 'My Pricing',
      icon: DollarOutlined,
      breadcrumb: false,
      submenu: []
    },
    // {
    //   key: 'api-keys',
    //   path: `${APP_PREFIX_PATH}/admin/apikeys`,
    //   title: 'Api Keys',
    //   icon: ApiOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'integrationdocs',
      path: `${APP_PREFIX_PATH}/admin/integrationdocs`,
      title: 'Integration Docs',
      icon: InteractionOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'admin-settings',
      path: `${APP_PREFIX_PATH}/admin/adminsettings`,
      title: 'Settings',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  'fees-management',
      path: `${APP_PREFIX_PATH}/admin/feesmanagement`,
      title:  'Fees Management',
      icon:  WalletOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  'add-transaction',
      path: `${APP_PREFIX_PATH}/admin/transactiondetails`,
      title:  'Credit Management', //Add Transaction
      icon:  UnorderedListOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  'total-users',
      path: `${APP_PREFIX_PATH}/admin/totalusers`,
      title:  'Vendors',
      icon:  TeamOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  'registration-logs',
      path: `${APP_PREFIX_PATH}/admin/reglogs`,
      title:  'Pre-Registration Logs',
      icon:  FileProtectOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  're-registration-logs',
      path: `${APP_PREFIX_PATH}/admin/rereglogs`,
      title:  'Registration Logs',
      icon:  FileProtectOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key:  'registered-users',
      path: `${APP_PREFIX_PATH}/admin/regusers`,
      title:  'Registered Users',
      icon:  FileProtectOutlined ,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'search-user',
      path: `${APP_PREFIX_PATH}/admin/searchuser`,
      title: 'Search User',
      icon: SearchOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'otp-logs',
      path: `${APP_PREFIX_PATH}/admin/otplogs`,
      title: 'OTP Logs',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'sms-config',
      path: `${APP_PREFIX_PATH}/admin/smsconfig`,
      title: 'SMS Config',
      icon: MobileOutlined,
      breadcrumb: false,
      submenu: []
    },
    
    {
      key: 'vendor-settings',
      path: `${APP_PREFIX_PATH}/admin/vendorsettings`,
      title: 'Vendor Settings',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
    
    {
      key: 'version-update',
      path: `${APP_PREFIX_PATH}/admin/versionupdate`,
      title: 'Version Update',
      icon: PushpinOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'vendor-config',
      path: `${APP_PREFIX_PATH}/admin/vendorconfig`,
      title: 'Vendor Config',
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'on-prem',
      path: `${APP_PREFIX_PATH}/admin/onprem`,
      title: 'Register On-Prem' ,
      icon: SettingOutlined,
      breadcrumb: false,
      submenu: []
    }
  ],
}]




const resourcesNavtree = [{
  key: 'resources',
  path: `${APP_PREFIX_PATH}/resources`,
  title: 'Resources',
  icon: SnippetsOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'integrationdocs',
    //   path: `${APP_PREFIX_PATH}/resources/integrationdocs`,
    //   title: 'Integration Docs',
    //   icon: InteractionOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    // {
    //   key: 'api-docs',
    //   path: `${APP_PREFIX_PATH}/resources/apidocs`,
    //   title: 'Api Docs',
    //   icon: CodeOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },
    {
      key: 'support-center',
      path: `${APP_PREFIX_PATH}/resources/supportcenter`,
      title: 'Support Center',
      icon: ShakeOutlined,
      breadcrumb: false,
      submenu: []
    }
  ]
}]

let navigationConfig = [
]

if(isNapidAdmin === "Nap Id Admin") {
  navigationConfig = [
    ...defaultNavTree,
   // ...usersNavTree,
    ...adminNapNavTree,
    ...resourcesNavtree
  ]
} else {
  navigationConfig = [
    ...defaultNavTree,
    ...usersNavTree,
    ...adminNavTree,
    ...resourcesNavtree
  ]
}


export default navigationConfig;
