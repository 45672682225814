import React, {useState, useEffect} from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import moment from "moment";
import { EditOutlined, LogoutOutlined } from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import utils from 'utils';
import axios from 'axios';
import { API_BASE_URL } from "../../constants/ApiConstant";

const baseUrl = API_BASE_URL;
 

const companyImage = localStorage.getItem("image");
const appU = localStorage.getItem("appU");
const companyName = localStorage.getItem("name");
const jwtKey = localStorage.getItem("jwtKey");
const menuItem = [
  {
    title: "Logout",
    icon: LogoutOutlined,
    path: "/auth/login",
  },
];

export const NavProfile = ({ signOut }) => {
  const name = localStorage.getItem("name");
  const [userMail, setUserMail] = useState(String);


  useEffect(() => {
    let mounted = true;
    let body = {
      appUserSno: appU,
    };
    axios({
      method: "post",
      headers: { "Content-Type": "application/json" , "Authorization" : "Bearer "+jwtKey},
      url: `${baseUrl}portal/vendorSettings`,
      data: body,
    })
      .then((response) => {
        if (response != null) {
          let data = response.data.email;
          if(mounted) {setUserMail(data);}
        }
      })
      return () => mounted = false
  }, [])

  const logOut = () => {
    localStorage.clear();
    window.location.reload();
    sessionStorage.setItem("go", '@@$%#$#^@&**(*!(*!)!)!!)!)!%$$#GHVGHVHJ');
  };

  const profileImg = "";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
        {
            companyImage != null && companyImage != undefined && companyImage != ''  ?
            <Avatar size={45} src={companyImage} /> :
            <Avatar size={45} className="font-size-lm" style={{backgroundColor: '#ff0000'}}>
            {utils.getNameInitial(name)}
          </Avatar>
          }
          <div className="pl-3">
            <h4 className="mb-0" style={{color: '#ff0000'}}>{name}</h4>
            <span >{userMail}</span>
            {/* <p className="text-muted">{`Last login: ${moment().format("DD hh:mm")}`}</p> */}
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i} onClick={() => logOut()}>
                <a href={el.path}>
                  <Icon className="mr-3" type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })}
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item>
          {
            companyImage != null && companyImage != undefined && companyImage != ''  ?
            <Avatar size={45} src={companyImage} /> :
            <Avatar size={45} className="font-size-lm" style={{backgroundColor: '#ff0000'}}>
            {utils.getNameInitial(name)}
          </Avatar>
          }
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
